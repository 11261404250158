import { rem } from 'polished';
import styled from 'styled-components';

export const AccordionCardContainer = styled.div`
  margin: ${rem('60px')} 0;
  display: flex;
  flex-direction: column;
  width: ${rem('400px')};
  max-width: 90%;
  gap: ${rem('10px')};
  background-color: var(--white);
  border-radius: ${rem('6px')};
  box-shadow: 0 0 ${rem('10px')} 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

export const AccordionCardImage = styled.img`
  width: 100%;
  height: ${rem('225px')};
  object-fit: cover;
`;

export const AccordionTitle = styled.div`
  font-size: ${rem('28px')};
`;

export const CollapsibleWrapperStyles = styled.div`
  [data-component-name='Interaction'] {
    align-items: baseline;
    padding-right: ${rem('20px')};
  }
`;

export const CollapsibleHeaderStyles = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem('20px')};
  span {
    font-size: ${rem('20px')};
    font-weight: 100;
    margin-top: ${rem('10px')};
  }
`;

export const BottomAccordionCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem('20px')};
  padding-top: 0;
`;

export const Divider = styled.div`
  border-bottom: 1px solid #f2f2f2;
  margin: ${rem('10px')} 0;
  width: 120%;
  margin-left: -${rem('20px')};
`;

export const AccordionCardFeature = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem('10px')};
  margin: ${rem('10px')} 0;
`;
