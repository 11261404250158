import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const NavigationalTabsWrapper = styled.div<{ sticky?: boolean; tabsNumber: number }>`
  > [class*='FunctionalTabsstyle__Tabs-sc'] {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    > [role='tabpanel'][class*='FunctionalTabPanelstyle__TabPanel-sc'] {
      position: static;
      opacity: 1;
      height: auto;
      overflow: hidden;
      width: 100%;

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        [class*='CustomElement'] {
          [class*='WrapItem']:nth-last-of-type(1) {
            [class*='Blockstyle__Block'] {
              padding: 0 ${rem('16px')} ${rem('32px')};
            }
          }
        }
      }

      > div {
        > div {
          > h2[class*='Headingstyle__Heading'] {
            color: var(--mineShaft);
            font-size: ${rem('36px')};
            line-height: ${rem('36px')};
            margin-block: ${rem('32px')};
            padding-inline: ${rem('16px')};

            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
              font-size: ${rem('40px')};
              letter-spacing: 0;
              line-height: ${rem('43px')};
              margin-block: ${rem('50px')};
            }

            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
              letter-spacing: ${rem('-0.8px')};
              margin-block: ${rem('50px')};
            }

            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
              letter-spacing: ${rem('-0.208px')};
              line-height: ${rem('48px')};
            }
          }
        }
      }
    }
  }

  [class*='NavigationalTabsstyle__TabList-sc'] {
    background: #f2f2f2;
    display: none;
    margin-inline: ${rem('-10px')};
    top: ${rem('114px')};
    z-index: 5;

    ${({ sticky }) =>
      sticky &&
      `
        position: sticky;
        top: ${rem('108px')};
      `}

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      display: grid;
      grid-auto-flow: column;
      justify-content: center;
      align-content: end;
      width: 100%;
    }
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
      display: grid;
      top: ${rem('70px')};
      grid-auto-flow: column;
      justify-content: center;
      align-content: end;
      width: 100%;
    }
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      display: ${({ sticky }) => (sticky ? 'grid' : 'none')};
      top: ${rem('58px')};
    }
  }

  [class*='TabTitlestyle__TabTitleWrapper-sc'] {
    width: ${rem('146px')};

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      width: ${rem('180px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
      min-height: ${rem('38px')};
      min-width: ${rem('180px')};
      width: auto;
    }

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      width: ${rem('106px')};
    }
  }

  [class*='FunctionalTabstyle__ClickableTab-sc'] {
    width: 100%;
    height: 100%;

    &[aria-selected='true'] {
      &::after {
        display: none;
      }

      > [class*='TabTitlestyle__TabTitle-sc'] {
        color: ${({ theme }) => theme.color.primary1.default};
        border-color: ${({ theme }) => theme.color.primary1.default};
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
          font-size: ${rem('14px')};
        }

        &::after {
          display: block;
          content: '';
          border-left: ${rem('7px')} solid transparent;
          border-right: ${rem('7px')} solid transparent;
          border-top: ${rem('7px')} solid ${({ theme }) => theme.color.primary1.default};
          bottom: -8px;
          position: absolute;
          transform: translateX(-50%);
          visibility: visible;
          width: 0;
          height: 0;
          left: 50%;
        }
      }
    }

    > [class*='TabTitlestyle__TabTitle-sc'] {
      border-color: ${({ theme }) => theme.color.disabled.default};
      border-width: ${rem('1px')};
      color: ${({ theme }) => theme.colors?.mineShaft};
      font-size: ${rem('18.88px')};
      font-weight: 400;
      height: 100%;
      line-height: ${rem('30px')};
      padding: 0;

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
        padding: 0 ${rem('10px')};
      }
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        font-size: ${rem('14px')};
      }

      &::after {
        display: none;
      }

      span {
        display: block;
        padding: 0;
        position: static;
        transform: none;
        transition: 0.4s all ease;
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
          text-wrap: auto;
          line-height: ${rem('18px')};
          padding: ${rem('10px')} 0;
        }
      }
    }
  }
`;

export const CustomElement = styled(Flex)`
  flex-direction: column;
`;
