import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { Heading } from '@uk-source-web/heading';
import { Paragraph } from '@uk-source-web/paragraph';
import { deleteBasket, useAppDispatch, useAppSelector, UserBasketItem } from '@/store';
import { SWBasketProps } from './SWBasket.types';

import { ReactComponent as EmptyBasketIcon } from '../../../../images/icon/empty-basket.svg';

import { useModalContext } from '@/hooks/modal/useModal';
import {
  CreditVettingPayload,
  ProductOrderBasketProduct,
  creditVettingProductOrder,
  productOrder,
} from '@/services/product.services';

import BasketItem from './BasketItem';
import { PRODUCT_BUNDLE_TYPENAME, transformToItemOrder } from './SWBasket.helper';

import SWBasketStyles, {
  BasketButtonsInner,
  ContainerHeaderStyles,
  EmptyBasket,
  SWBasketContainer,
  SWBasketHeader,
  SWBasketSummary,
  SWBasketSummaryInner,
  WrapperBasketButtons,
} from './SWBasket.styles';
import handleTranslation from '@/helpers/handleTranslation';
import { BasketHorizontalStepper } from './BasketHorizontalStepper/BasketHorizontalStepper';

import usingOpcoUrl from '@/helpers/prefixHelper';
import BasketDiscount from './BasketDiscount/BasketDiscount';
import BasketStrip from './BasketStrip/BasketStrip';
import { checkForZeroOrNull } from '@/helpers/nullOrZero';
import { Button } from '@uk-source-web/button';
import { OPCOOptions } from '@/types/Services.types';
import { CreditVettingForm, CreditVettingFormRef } from '../CreditVettingForm/CreditVettingForm';

//tenant context
import { useValid } from '@/contexts/TenantContext/TenantContext';

const OPCO = process.env.GATSBY_OPCO;

const SWBasket = ({ name, continueShoppingButton, requestCallbackButton, goToCheckoutButton }: SWBasketProps) => {
  const { width } = useWindowSize();
  const { toggleModal, setErrorInfo } = useModalContext();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ userSession }) => userSession);
  const { userBasket } = useAppSelector(({ userBasket: { data } }) => ({
    userBasket: data,
  }));
  const [inlineNotificationVisible, setInlineNotificationVisible] = useState(false);

  const yourOrderSummary = handleTranslation('Your Order Summary', 'Carrinho de compras');
  const emptyBasket = handleTranslation('Your basket is empty', 'O seu carrinho está vazio');
  const emptyBasketSubtitle = handleTranslation(
    'The items you add will appear here',
    'Os itens que você adicionar aparecerão aqui'
  );
  const accountTrans = handleTranslation('Account', 'Conta');

  const [currentStep, setCurrentStep] = useState(1);

  const [disableContinueButton, setDisableContinueButton] = useState<boolean>(true);

  //for microsoft products
  const [microsoftAdditionalData, setMicrosoftAdditionalData] = useState<string>(() => {
    return localStorage.getItem('microsoftAdditionalData') || 'warn';
  });

  const setMicrosoftAdditionalDataState = (data: string) => {
    setMicrosoftAdditionalData(data);
  };

  //determine the initial state of continue purchase button and enable it even after quantity
  useEffect(() => {
    const hasMicrosoftProduct = userBasket.items.some(item => item.msftProduct);

    if (hasMicrosoftProduct && microsoftAdditionalData !== 'success') {
      setDisableContinueButton(true);
    } else {
      setDisableContinueButton(false);
    }
  }, [userBasket.items, microsoftAdditionalData]);

  const creditVettingFormRef = useRef<CreditVettingFormRef>(null);

  const handleContinueClick = () => {
    if (creditVettingFormRef.current) {
      creditVettingFormRef.current.submit();
    }
  };

  //tenant context
  const {
    tenantName,
    setTenantName,
    isTenantValid,
    setIsTenantValid,
    principleName,
    setPrincipleName,
    isPrincipleNameValid,
    setIsPrincipleNameValid,
    email,
    setEmail,
    isEmailValid,
    setEmailValid,
    firstName,
    setFirstName,
    isFirstNameValid,
    setFirstNameValid,
    lastName,
    setLastName,
    isLastNameValid,
    setLastNameValid,
    businessName,
    setBusinessName,
    isBusinessNameValid,
    setBusinessNameValid,
    address,
    setAddress,
    isAddressValid,
    setAddressValid,
    address2,
    setAddress2,
    isAddress2Valid,
    setAddress2Valid,
    zipCode,
    setZipCode,
    isZipCodeValid,
    setZipCodeValid,
    city,
    setCity,
    isCityValid,
    setCityValid,
    country,
    setCountry,
    isCountryValid,
    setCountryValid,
    phone,
    setPhone,
    isPhoneValid,
    setPhoneValid,
    orgNumber,
    setOrgNumber,
    isOrgNumberValid,
    setOrgNumberValid,
    state,
    setState,
    isStateValid,
    setStateValid,
    validForm,
    setValidForm,
    altEmail,
    isAltEmailValid,
    setAltEmail,
    setAltEmailValid,
  } = useValid();

  const getMicrosoftFields = () => ({
    vendorRequiredFields: {
      organizationRegistrationNumber: [orgNumber || ''],
      subDomain: [tenantName || ''],
      userPrincipleName: [principleName || ''],
      firstName: [firstName || ''],
      lastName: [lastName || ''],
      addressLine1: [address || ''],
      addressLine2: [address2 || ''],
      addressCity: [city || ''],
      addressState: [state || ''],
      addressPostalCode: [zipCode || ''],
      alternateEmail: [altEmail || ''],
      email: [email || ''],
      addressPhoneNumber: [phone || ''],
      addressISO3Country: [country || ''],
    },
  });

  const hasMicrosoftProduct = userBasket.items.some(item => item.msftProduct === true);
  let firstMicrosoftProduct: UserBasketItem | undefined;
  if (hasMicrosoftProduct) {
    firstMicrosoftProduct = userBasket.items.find(item => item.msftProduct === true);
  }

  const req_body: ProductOrderBasketProduct = {
    productUuid: firstMicrosoftProduct?.id || '',
    qty: firstMicrosoftProduct?.quantity || 1,
    unit: firstMicrosoftProduct?.dxlInformation?.price[0].unit || 'USER',
    vendorRequiredFields: {
      organizationRegistrationNumber: [orgNumber],
      subDomain: [tenantName],
      userPrincipleName: [principleName],
      firstName: [firstName],
      lastName: [lastName],
      addressLine1: [address],
      addressLine2: [address2],
      addressCity: [city],
      addressState: [state],
      addressPostalCode: [zipCode],
      alternateEmail: [email],
      addressPhoneNumber: [phone],
      addressISO3Country: [country],
    },
  };
  const req_body_keys = Object.keys(req_body.vendorRequiredFields);

  const postProductOrder = async (e: { preventDefault: () => void }) => {
    // add domain validation lodic here

    if (currentStep === 2) {
      handleContinueClick();
      return;
    }
    e.preventDefault();
    toggleModal(true, 'LoadingCheckout');

    if (
      user?.organizationId &&
      user?.userId &&
      userBasket &&
      userBasket.items.length > 0 &&
      !userBasket.items.find(item => !item.dxlInformation?.periods || !item.dxlInformation?.periods[0].uuid)
    ) {
      const products = userBasket.items.flatMap(item => {
        const { quantity } = item;

        const isMicrosoftProduct = item.msftProduct;

        // For bundled products, include the free items as well
        if (
          item.__typename === PRODUCT_BUNDLE_TYPENAME &&
          item.productsFreeOfCharge &&
          item.productsFreeOfCharge.length > 0
        ) {
          return [
            {
              ...transformToItemOrder(item, undefined),
              ...(isMicrosoftProduct ? getMicrosoftFields() : {}),
            },
            ...item.productsFreeOfCharge.map(subItem => ({
              ...transformToItemOrder(subItem, quantity),
              ...(isMicrosoftProduct ? getMicrosoftFields() : {}),
            })),
          ];
        } else if (item.__typename === PRODUCT_BUNDLE_TYPENAME && !item.productsFreeOfCharge) {
          return [
            {
              ...transformToItemOrder(item, undefined),
              ...(isMicrosoftProduct ? getMicrosoftFields() : {}),
            },
          ];
        }

        // Regular products
        return [
          {
            ...transformToItemOrder(item, undefined),
            ...(isMicrosoftProduct ? getMicrosoftFields() : {}),
          },
        ];
      }) as unknown as ProductOrderBasketProduct[];

      //making the api call for validation
      const res = await productOrder(
        {
          basket: {
            organizationId: user?.organizationId,
            userId: user?.userId,
            // INFO: The order sent to the marketplace need to be checked if it's being calculated right, because here
            // we are just sending the prices to cost one, maybe we should send every unit price related to the product.
            products,
          },
        },
        user.csrfToken || '',
        setErrorInfo,
        toggleModal
      );
      toggleModal(false, 'LoadingCheckout');

      // for creditvetting validation
      if (res && res?.data?.needCreditVetting) {
        setDisableContinueButton(true);
        setCurrentStep(2); // go to review ( CV ) page
      } else if (res?.data?.externalId) {
        // for successful purchase
        dispatch(deleteBasket());
        return usingOpcoUrl(`/new-order-flow-success-page?id=${res.data.id}&email=${res.data.userEmail}`, true);
      }
    }
  };

  const handleCreditVettingSubmit = async (payload: CreditVettingPayload) => {
    const validateFields = (errorMsg: string | null, req_body_keys: string[]) => {
      if (!errorMsg) return true; // Return true if no errorMsg is provided

      const regex =
        /ValidationItem\(itemId=([a-z0-9-]+),\s*code=([A-Z_]+),\s*message=(.+?)(?=,\s*messageParameters=\[),\s*messageParameters=\[([^\]]+)\]\)/g;

      let match;
      const validationItems = [];

      while ((match = regex.exec(errorMsg)) !== null) {
        // Use a dedicated regex to extract each MessageParameter
        const paramRegex = /MessageParameter\(key=([^,]+),\s*value=([^)]+)\)/g;
        let paramMatch;
        const params: { key: string; value: string }[] = [];
        while ((paramMatch = paramRegex.exec(match[4])) !== null) {
          params.push({
            key: paramMatch[1].trim(),
            value: paramMatch[2].trim(),
          });
        }

        const item = {
          itemId: match[1],
          code: match[2],
          message: match[3],
          messageParameters: params,
        };
        validationItems.push(item);
      }

      if (!validationItems.length) {
        return true; // If no items, form is considered valid
      }

      const reqKeysResponse = [
        'SubDomain',
        'UserPrincipleName',
        'FirstName',
        'LastName',
        'Address/Line1',
        'Address/ISO3Country',
        'Address/PhoneNumber',
        'AlternateEmail',
        'Address/PostalCode',
        'addressLine2',
        'Address/City',
        'Address/State',
        'OrganizationRegistrationNumber',
      ];

      const validationKeys = validationItems
        .map(item => {
          const match = item.message.match(/'([^']+)'/);
          return match ? match[1] : null;
        })
        .filter(Boolean);

      const matchingKeys = reqKeysResponse.filter(reqKey => validationKeys.includes(reqKey));
      reqKeysResponse.forEach(key => {
        const isMatching = matchingKeys.includes(key);
        const isValid = !isMatching;

        switch (key) {
          case 'OrganizationRegistrationNumber':
            setOrgNumberValid(isValid);
            break;
          case 'SubDomain':
            setIsTenantValid(isValid);
            break;
          case 'UserPrincipleName':
            setIsPrincipleNameValid(isValid);
            break;
          case 'FirstName':
            setFirstNameValid(isValid);
            break;
          case 'LastName':
            setLastNameValid(isValid);
            break;
          case 'Address/Line1':
            setAddressValid(isValid);
            break;
          case 'addressLine2':
            setAddress2Valid(isValid);
            break;
          case 'Address/City':
            setCityValid(isValid);
            break;
          case 'Address/State':
            setStateValid(isValid);
            break;
          case 'Address/PostalCode':
            setZipCodeValid(isValid);
            break;
          case 'AlternateEmail':
            setAltEmailValid(isValid);
            break;
          case 'email':
            setEmailValid(isValid);
            break;
          case 'Address/PhoneNumber':
            setPhoneValid(isValid);
            break;
          case 'Address/ISO3Country':
            setCountryValid(isValid);
            break;
        }
      });

      if (matchingKeys.length > 0) {
        setValidForm(false);
      } else {
        setValidForm(true);
      }

      return true;
    };

    if (payload.bankStartDay == null || payload.businessStartDate == null) {
      return;
    }
    toggleModal(true, 'LoadingCheckout');
    const res = await creditVettingProductOrder(payload, user?.csrfToken || '', setErrorInfo, toggleModal, false);
    toggleModal(false, 'LoadingCheckout');

    if (res && res?.response?.data?.message) {
      // Extract errorMsg and call validateFields
      const errorMsg = res?.response?.data?.message;
      const isValid = validateFields(errorMsg, req_body_keys);
    }

    if (
      res &&
      !(res?.response?.data?.error && typeof res?.response?.data?.error === 'string') &&
      res.name !== 'AxiosError'
    ) {
      dispatch(deleteBasket());
      return usingOpcoUrl(`/new-order-flow-success-page?id=${res.data.externalId}&email=${res.data.userEmail}`, true);
    } else {
      setCurrentStep(1);
      setInlineNotificationVisible(true);
    }
  };

  const handleCreditVettingChange = (payload: CreditVettingPayload) => {
    if (
      payload.bankStartDay == null ||
      payload.bankStartDay == '' ||
      payload.businessStartDate == null ||
      payload.businessStartDate == ''
    ) {
      setDisableContinueButton(true);
    } else {
      setDisableContinueButton(false);
    }
  };

  const montlyCostValue = checkForZeroOrNull(userBasket.total?.monthly?.gross, 'NA');
  const upfrontCostValue = checkForZeroOrNull(userBasket.total?.upfront?.gross, 'NA');

  const { discountData } = useAppSelector(state => state.userDiscount);

  const handleSetDisableContinueButton = useCallback((value: boolean | ((prevState: boolean) => boolean)) => {
    setDisableContinueButton(value);
  }, []);

  const BasketContent = () => {
    return (
      <SWBasketSummaryInner>
        <Heading level={2} size={2} weight={3} text={yourOrderSummary} />
        {userBasket.items.map(product => {
          if (product.__typename === PRODUCT_BUNDLE_TYPENAME) {
            const discountValue = discountData?.discountValue;
            const productDiscount = discountData?.itemDiscount?.find(item => item.productId === product.productId);
            const hasDiscount =
              (productDiscount?.productRecurringDiscount && productDiscount.productRecurringDiscount > 0) ||
              (productDiscount?.productUpfrontDiscount && productDiscount.productUpfrontDiscount > 0);
            const discountObj = useMemo(
              () => ({
                discountData: hasDiscount ? discountValue : undefined,
              }),
              [hasDiscount, discountValue]
            );

            return (
              <>
                <BasketItem
                  key={product.id}
                  {...product}
                  discountData={discountObj}
                  continueBtn={handleSetDisableContinueButton}
                  microsoftItemState={setMicrosoftAdditionalData}
                  microsoftState={microsoftAdditionalData}
                />
                {product?.productsFreeOfCharge?.map(productFreeOfCharge => (
                  <BasketItem
                    key={productFreeOfCharge.id}
                    {...productFreeOfCharge}
                    increaseDecreaseAct={false}
                    quantity={product.quantity}
                  />
                ))}
              </>
            );
          }
        })}
      </SWBasketSummaryInner>
    );
  };

  return (
    <SWBasketStyles>
      <SWBasketContainer {...ContainerHeaderStyles(width)}>
        <SWBasketHeader>
          <Heading level={3} justify={'center'} text={name} />
          <Paragraph size={2}>
            <span>{accountTrans}</span>
            <br />
            <b>{user?.selectedAccount?.customerAccountId}</b>
          </Paragraph>
        </SWBasketHeader>
      </SWBasketContainer>
      {userBasket?.items.length > 0 ? (
        <>
          <BasketHorizontalStepper currentStep={currentStep} />
          <SWBasketContainer backgroundColor="#F4F4F4">
            <SWBasketSummary>
              {currentStep === 1 && <BasketContent />}
              {currentStep === 2 && (
                <CreditVettingForm
                  ref={creditVettingFormRef}
                  description="Please validate bank registration details before proceeding to the next step"
                  __typename="ContentfulSectionCreditVettingForm"
                  id="credit-vetting-form-basket"
                  onSubmit={handleCreditVettingSubmit}
                  onChange={handleCreditVettingChange}
                  inlineNotificationVisible={inlineNotificationVisible}
                  inlineNotificationMessage="Credit vetting failed. Please select the correct information or contact sales agent."
                />
              )}
            </SWBasketSummary>
            <BasketDiscount shouldShowDiscountInput={currentStep === 1} />
          </SWBasketContainer>

          <BasketStrip
            continueShoppingButton={continueShoppingButton}
            requestCallbackButton={requestCallbackButton}
            goToCheckoutButton={goToCheckoutButton}
            postProductOrder={postProductOrder}
            disableContinueButton={disableContinueButton}
            hideContinueShoppingButton={currentStep === 2}
          />
        </>
      ) : (
        <SWBasketContainer backgroundColor="#F4F4F4">
          <SWBasketSummary>
            <EmptyBasket>
              <Heading size={2} level={3} weight={3}>
                {emptyBasket}
              </Heading>
              <Paragraph weight={1}>{emptyBasketSubtitle}</Paragraph>
              <WrapperBasketButtons>
                <BasketButtonsInner>
                  {OPCO !== OPCOOptions.PORTUGAL && (
                    <Button
                      text={continueShoppingButton.label}
                      appearance="primary"
                      href={continueShoppingButton.url}
                      data-selector="MS365-BundleConfig-OrderSummary-Next"
                    />
                  )}
                  {requestCallbackButton && (
                    <Button
                      text={requestCallbackButton.label}
                      appearance="alt1"
                      href={requestCallbackButton?.url}
                      data-selector="MS365-BundleConfig-OrderSummary-RequestCallback"
                    />
                  )}
                </BasketButtonsInner>
              </WrapperBasketButtons>
            </EmptyBasket>
          </SWBasketSummary>
        </SWBasketContainer>
      )}
    </SWBasketStyles>
  );
};

export default SWBasket;
