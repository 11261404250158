import styled from '@emotion/styled';
import { getSrc } from 'gatsby-plugin-image';
import { rem } from 'polished';

import { SWSectionFiftyFiftyBannerProps } from './SWSectionFiftyFiftyBanner.types';
import { css } from '@emotion/react';

type FiftyFiftyBannerWrapperProps = Pick<
  SWSectionFiftyFiftyBannerProps,
  'backgroundColor' | 'bodyPosition' | 'copyBackground' | 'fontColor'
> & {
  buttonAppearance?: 'primary' | 'secondary';
};

export const FiftyFiftyBannerWrapper = styled.div<FiftyFiftyBannerWrapperProps>`
  width: 100%;
  h2[size='3'] {
    font-size: ${rem('40px')};
    line-height: ${rem('46px')};
    margin-bottom: ${rem('16px')};

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      text-align: center;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      margin-bottom: ${rem('1px')};
    }
  }

  p {
    font-size: ${rem('18px')};
    line-height: ${rem('24px')};
    font-family: VodafoneLight;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      text-align: center;
    }
  }

  [class*='FiftyFiftyBannerstyle__FiftyFiftyBanner'] {
    background-image: ${({ copyBackground }) => (copyBackground ? `url(${getSrc(copyBackground)})` : 'none')};
    background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.color.primary2.default};
    overflow: hidden;

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      min-height: ${rem('334px')};
      flex-direction: ${({ bodyPosition }) => {
        if (bodyPosition === 'left') {
          return 'row';
        } else if (bodyPosition === 'right') {
          return 'row-reverse';
        } else {
          return 'row';
        }
      }};
    }
    [class*='Headingstyle__Heading-sc'] {
      color: ${({ fontColor }) => fontColor || 'inherit'};
    }
    [class*='Paragraphstyle__Paragraph-sc'] {
      color: ${({ fontColor }) => fontColor || 'inherit'};
    }
    > [class*='BannerContent'] {
      background-image: ${({ copyBackground }) => (copyBackground ? `url(${getSrc(copyBackground)})` : 'none')};
      background-position-x: ${({ bodyPosition }) => (bodyPosition === 'right' ? '65%' : '0')};
      background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.color.primary2.default};

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        justify-content: ${({ bodyPosition }) => (bodyPosition === 'right' ? 'flex-start' : 'flex-end')};
        transform: ${({ bodyPosition }) => (bodyPosition === 'right' ? 'skewX(-22.7deg)' : 'skewX(22.7deg)')};
        z-index: 2;
        margin-right: ${({ bodyPosition }) => (bodyPosition === 'right' ? '0' : '-5.063rem')};
        margin-left: ${({ bodyPosition }) => (bodyPosition === 'right' ? '-5.063rem' : '0')};
        width: '45%';
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
        margin-right: ${({ bodyPosition }) => (bodyPosition === 'right' ? '-3rem' : '-5.063rem')};
        margin-left: ${({ bodyPosition }) => (bodyPosition === 'right' ? '-5.063rem' : '0')};
        width: 50%;
      }
    }

    > [class*='BannerContent'] {
      > [class*='ContentWrapper'] {
        left: ${rem('50px')};
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
          align-self: stretch;
          margin: ${rem('24px')} 0 ${rem('32px')};

          [class*='BannerBody'] {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          width: ${rem('440px')};
          margin: ${({ bodyPosition }) => (bodyPosition === 'right' ? '0 3rem 0 0' : '0 2rem 0 0')};
          padding: ${({ bodyPosition }) => (bodyPosition === 'right' ? '0 1rem 0 1rem' : '0 0 0 1rem')};
          transform: ${({ bodyPosition }) => (bodyPosition === 'right' ? 'skewX(22.7deg)' : 'skewX(-22.7deg)')};
          position: ${({ bodyPosition }) => (bodyPosition === 'right' ? 'relative' : '')};
          left: ${rem('8px')};
        }
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
          margin: ${({ bodyPosition }) => (bodyPosition === 'right' ? '0 3rem 0 10.2rem' : '0 10.2rem 0 1rem')};
          padding: 0;
          left: ${rem('50px')};

          width: ${rem('410px')};
          position: unset;
        }

        > [class*='BannerBodystyle__BannerBody'] {
          > [class*='BannerContent'] {
            @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
              width: ${rem('320px')};
              margin-bottom: ${rem('48px')};
            }
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
              margin-bottom: ${rem('23px')};
            }
          }

          > [class*='BannerButtons'] {
            width: ${rem('216px')};
            a {
              font-size: ${rem('20px')};
              line-height: ${rem('23px')};
              width: 100%;
              border: 0;
              height: ${rem('50px')};
              padding: 0;
              position: static;
              ${({ buttonAppearance }) =>
                buttonAppearance === 'secondary' &&
                css`
                  background-color: #fff;
                  color: #000;
                `}
            }
            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
              width: ${rem('228px')};
            }
          }
        }
      }
    }

    > [class*='BannerImage'] {
      flex-grow: 1;

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        min-height: ${rem('180px')};
      }
    }

    > [class*='BannerContent'],
    > [class*='BannerImage'] {
      padding: 0;

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        min-height: auto;
      }
    }

    [class*='Interactionstyle__Interaction'] {
      font-size: ${rem('20px')};
      line-height: ${rem('18px')};
      padding: ${rem('24px')} ${rem('45px')};
      top: ${rem('3px')};
    }
  }

  &.vhubKnowledgeCentreBanner {
    [class*='FiftyFiftyBannerstyle__FiftyFiftyBanner']
      > [class*='BannerContent']
      > [class*='ContentWrapper']
      > [class*='BannerBodystyle__BannerBody']
      > [class*='BannerButtons'] {
      width: fit-content;

      a {
        padding: ${rem('24px')};
      }
    }
  }

  &.centreBanner {
    width: 100%;
    background: linear-gradient(
      #f4f4f4 0%,
      #f4f4f4 50%,
      ${({ theme }) => theme.color.primary2.default} 50%,
      ${({ theme }) => theme.color.primary2.default} 100%
    );

    [class*='FiftyFiftyBannerstyle__FiftyFiftyBanner'] {
      width: 100%;
      max-width: ${rem('1180px')};
      margin: 0 auto;
      border-radius: ${rem('50px')};
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        /* border-radius: unset; */
      }

      > [class*='BannerContent'] {
        width: 50%;
        transform: none;

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
          margin: 0;
        }

        h2,
        p {
          color: ${({ theme }) => theme.color.primary2.default};
          text-align: left;

          @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
            text-align: center;
          }
        }

        h2 {
          font-family: 'VodafoneRegular';
          margin-bottom: ${rem('1px')};
          padding: 0;
        }

        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
          width: 100%;
        }

        > [class*='ContentWrapper'] {
          transform: none;
          margin: ${rem('64px')} ${rem('132px')} ${rem('64px')} ${rem('56px')};

          @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
            margin: ${rem('24px')} 0 ${rem('32px')};
          }
        }
      }
    }
  }
`;
