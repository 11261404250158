import { rem } from 'polished';

import styled from '@emotion/styled';

import { Flex } from '@chakra-ui/react';

//size 2 used in: SmallPricingCard
//size 1 used in: TariffCards and is the default size
export const IconButtonWrapper = styled(Flex)<{ size: 1 | 2 | 3 }>`
  z-index: 1;

  ${({ size }) =>
    size === 2 &&
    `
      margin: 0 ${rem('4px')};
    `}
  button {
    border-radius: ${rem('50px')};
    width: ${rem('36px')};
    height: ${rem('36px')};
  }
  svg {
    width: ${rem('24px')};
    height: ${rem('24px')};
  }
  [class*='IconButtonstyle__IconButton-sc'] {
    background: transparent;

    [class*='Iconstyle__Icon-sc'] * {
      stroke: #007c92;
    }
  }
`;

export const InputWrapper = styled(Flex)<{ size: 1 | 2 | 3 }>`
  align-items: center;
  justify-content: center;

  [class*='TextInputstyle__TextInput-sc'] {
    color: var(--vodafone-color-mineShaft);
    font-size: ${rem('20px')};
    height: ${rem('50px')};
    line-height: ${rem('23px')};
    padding: ${rem('12px')} ${rem('4px')};
    text-align: center;
    width: ${rem('72px')};
    margin: 0 ${rem('12px')};
    ${({ size }) =>
      size === 2 &&
      `
        max-width: ${rem('72px')};
        max-height: ${rem('44px')};

      `}
  }
`;
