import { SectionCardDisplayProps } from './SectionCardDisplay.types';
import {
  SCDTitle,
  SDCDescription,
  SectionCardDisplayContainer,
  SectionCardDisplayWrapper,
  Divider,
} from './SectionCardDisplay.styles';
import { SWSmallPricingCard } from '../../Elements/SWSmallPricingCard/SWSmallPricingCard';
import SWSectionFiftyFiftyBanner from '../SWSectionFiftyFiftyBanner/SWSectionFiftyFiftyBanner';

//Is supposed to be a generic display of cards/elements
//Mobile view will change from row to column
const SectionCardDisplay = ({
  displayType,
  cards,
  title,
  description,
  backgroundColor,
  banner,
}: SectionCardDisplayProps) => {
  return (
    <>
      <SectionCardDisplayWrapper backgroundColor={backgroundColor}>
        <SCDTitle>{title}</SCDTitle>
        <Divider height="10px" />
        <SDCDescription>{description}</SDCDescription>
        <Divider height="20px" />
        <SectionCardDisplayContainer>
          {cards.map((card, index) => {
            if (displayType === 'smallPricingCard') {
              return <SWSmallPricingCard key={index} {...card} />;
            }
            return null;
          })}
        </SectionCardDisplayContainer>
      </SectionCardDisplayWrapper>
      {banner && <SWSectionFiftyFiftyBanner {...banner} />}
    </>
  );
};

export default SectionCardDisplay;
