import React from 'react';
import { UtilityCard as _UtilityCard } from '@uk-source-web/utility-card';
import { SWUtilityCardProps } from './SWUtilityCard.types';
import { renderToString } from 'react-dom/server';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { UtilityCardContainer } from './SWUtilityCard.styles';

const SWUtilityCard = ({
  heading,
  bodyText,
  iconName,
  linkWithIcon,
  elevation,
  orientation,
  verticalAlign,
  labelText,
  labelAppearance,
  align,
}: SWUtilityCardProps) => {
  const richTextBodyJson = bodyText && JSON.parse(bodyText.raw);
  const richTextBodyContent = richTextBodyJson && { ...richTextBodyJson, content: richTextBodyJson.content[0].content };

  return (
    <UtilityCardContainer align={align}>
      <_UtilityCard
        appearance="primary"
        heading={{ text: heading ?? '' }}
        text={bodyText ? renderToString(documentToReactComponents(richTextBodyContent)) : undefined}
        icon={{ name: iconName ?? 'info-circle' }}
        elevation={elevation ? elevation : false}
        orientation={orientation ? orientation : 'vertical'}
        linkWithIcon={
          linkWithIcon
            ? {
                text: `${linkWithIcon?.text ? linkWithIcon?.text : ''}`,
                icon: {
                  name: `${linkWithIcon?.icon ? linkWithIcon?.icon : 'arrow-right'}`,
                  justify: 'right',
                },
                href: `${linkWithIcon?.url}`,
              }
            : undefined
        }
        verticalAlign={verticalAlign ? verticalAlign : 'fill'}
        label={
          labelText
            ? {
                text: labelText ? labelText : '',
                appearance: labelAppearance ? labelAppearance : 'primary',
              }
            : undefined
        }
      />
    </UtilityCardContainer>
  );
};

export default SWUtilityCard;
