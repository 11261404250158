import { costOneUnit, retrievePriceByTypeOrFirstOne } from '@/helpers/findPrices';
import { UserBasketItem, UserBasketItemBase } from '@/store';

export const PRODUCT_BUNDLE_TYPENAME = 'ContentfulMarketplaceProductBundle';
export const NULL_PRICE_VALUE = 'NA';

export const getBundleIncludes = (item: UserBasketItem) =>
  item.__typename === PRODUCT_BUNDLE_TYPENAME && item.bundleIncludes ? item.bundleIncludes : [];

export const validatePriceUnit = (item: UserBasketItem | undefined, unit: string) =>
  item?.dxlInformation?.price.find(price => price.unit === unit);

export const getUpfrontPrice = (item: UserBasketItem, quantity: number = 1) => {
  // SHOULD BE MOVED TO THE BACK-END
  const hasMailBox = validatePriceUnit(item, 'MAILBOX');
  const hasSession = validatePriceUnit(item, 'SESSION');

  if (hasMailBox || hasSession) {
    const userSelectedPrice = item?.dxlInformation?.price.find(price => price.name === item.selectedPriceName);
    if (userSelectedPrice) {
      return userSelectedPrice.price * quantity;
    }
    if (
      item.__typename === PRODUCT_BUNDLE_TYPENAME &&
      item.msftProduct &&
      item.slug.includes('apphelp-microsoft-365-migration')
    ) {
      if (quantity <= 5) {
        return retrievePriceByTypeOrFirstOne(item?.dxlInformation?.price, 'Cost2');
      } else {
        return (
          (retrievePriceByTypeOrFirstOne(item?.dxlInformation?.price, 'Cost2') || 0) +
          (retrievePriceByTypeOrFirstOne(item?.dxlInformation?.price, 'Cost1') || 0) * (quantity - 5)
        );
      }
    } else {
      return retrievePriceByTypeOrFirstOne(item?.dxlInformation?.price, 'Cost1') * quantity;
    }
  }
};

export const getMonthlyPrice = (item: UserBasketItem) => {
  const hasMailBox = validatePriceUnit(item, 'MAILBOX');
  const hasSession = validatePriceUnit(item, 'SESSION');

  if (hasMailBox || hasSession) {
    return 0;
  } else {
    const userSelectedPrice = item?.dxlInformation?.price.find(price => price.name === item.selectedPriceName);
    if (userSelectedPrice) {
      return userSelectedPrice.price;
    }
    return retrievePriceByTypeOrFirstOne(item?.dxlInformation?.price, 'Cost1');
  }
};

export const transformToItemOrder = (item: UserBasketItem, quantity: UserBasketItemBase['quantity']) => {
  const productUuid =
    item.__typename === PRODUCT_BUNDLE_TYPENAME && item.selectedEdition
      ? item.selectedEdition
      : item?.dxlInformation?.periods[0]?.uuid;

  const unit =
    item.__typename === PRODUCT_BUNDLE_TYPENAME && item.selectedEdition
      ? costOneUnit(item.dxlInformation?.periods?.find(price => price.uuid === item.selectedEdition)?.prices)
      : costOneUnit(item.dxlInformation?.price);

  return {
    productUuid,
    qty: quantity || item.quantity,
    unit,
  };
};
