import React, { createContext, useState, useContext } from 'react';

interface TenantContextType {
  tenantName: string;
  setTenantName: React.Dispatch<React.SetStateAction<string>>;
  isTenantValid: boolean;
  setIsTenantValid: React.Dispatch<React.SetStateAction<boolean>>;

  principleName: string;
  setPrincipleName: React.Dispatch<React.SetStateAction<string>>;
  isPrincipleNameValid: boolean;
  setIsPrincipleNameValid: React.Dispatch<React.SetStateAction<boolean>>;

  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  isEmailValid: boolean;
  setEmailValid: React.Dispatch<React.SetStateAction<boolean>>;
  altEmail: string;
  setAltEmail: React.Dispatch<React.SetStateAction<string>>;
  isAltEmailValid: boolean;
  setAltEmailValid: React.Dispatch<React.SetStateAction<boolean>>;

  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  isFirstNameValid: boolean;
  setFirstNameValid: React.Dispatch<React.SetStateAction<boolean>>;

  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  isLastNameValid: boolean;
  setLastNameValid: React.Dispatch<React.SetStateAction<boolean>>;

  businessName: string;
  setBusinessName: React.Dispatch<React.SetStateAction<string>>;
  isBusinessNameValid: boolean;
  setBusinessNameValid: React.Dispatch<React.SetStateAction<boolean>>;

  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  isAddressValid: boolean;
  setAddressValid: React.Dispatch<React.SetStateAction<boolean>>;

  address2: string;
  setAddress2: React.Dispatch<React.SetStateAction<string>>;
  isAddress2Valid: boolean;
  setAddress2Valid: React.Dispatch<React.SetStateAction<boolean>>;

  zipCode: string;
  setZipCode: React.Dispatch<React.SetStateAction<string>>;
  isZipCodeValid: boolean;
  setZipCodeValid: React.Dispatch<React.SetStateAction<boolean>>;

  city: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  isCityValid: boolean;
  setCityValid: React.Dispatch<React.SetStateAction<boolean>>;

  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  isCountryValid: boolean;
  setCountryValid: React.Dispatch<React.SetStateAction<boolean>>;

  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  isPhoneValid: boolean;
  setPhoneValid: React.Dispatch<React.SetStateAction<boolean>>;

  orgNumber: string;
  setOrgNumber: React.Dispatch<React.SetStateAction<string>>;
  isOrgNumberValid: boolean;
  setOrgNumberValid: React.Dispatch<React.SetStateAction<boolean>>;

  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  isStateValid: boolean;
  setStateValid: React.Dispatch<React.SetStateAction<boolean>>;

  validForm: boolean;
  setValidForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const TenantContext = createContext<TenantContextType | undefined>(undefined);

export const TenantProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tenantName, setTenantName] = useState('');
  const [isTenantValid, setIsTenantValid] = useState(true);

  const [principleName, setPrincipleName] = useState('');
  const [isPrincipleNameValid, setIsPrincipleNameValid] = useState(true);

  const [email, setEmail] = useState('');
  const [isEmailValid, setEmailValid] = useState(true);

  const [altEmail, setAltEmail] = useState('');
  const [isAltEmailValid, setAltEmailValid] = useState(true);

  const [firstName, setFirstName] = useState('');
  const [isFirstNameValid, setFirstNameValid] = useState(true);

  const [lastName, setLastName] = useState('');
  const [isLastNameValid, setLastNameValid] = useState(true);

  const [businessName, setBusinessName] = useState('');
  const [isBusinessNameValid, setBusinessNameValid] = useState(true);

  const [address, setAddress] = useState('');
  const [isAddressValid, setAddressValid] = useState(true);

  const [address2, setAddress2] = useState('');
  const [isAddress2Valid, setAddress2Valid] = useState(true);

  const [zipCode, setZipCode] = useState('');
  const [isZipCodeValid, setZipCodeValid] = useState(true);

  const [city, setCity] = useState('');
  const [isCityValid, setCityValid] = useState(true);

  const [country, setCountry] = useState('');
  const [isCountryValid, setCountryValid] = useState(true);

  const [phone, setPhone] = useState('');
  const [isPhoneValid, setPhoneValid] = useState(true);

  const [orgNumber, setOrgNumber] = useState('');
  const [isOrgNumberValid, setOrgNumberValid] = useState(true);

  const [state, setState] = useState('');
  const [isStateValid, setStateValid] = useState(true);

  const [validForm, setValidForm] = useState(true);
  const value: TenantContextType = {
    tenantName,
    setTenantName,
    isTenantValid,
    setIsTenantValid,
    principleName,
    setPrincipleName,
    isPrincipleNameValid,
    setIsPrincipleNameValid,
    email,
    setEmail,
    isEmailValid,
    setEmailValid,
    firstName,
    setFirstName,
    isFirstNameValid,
    setFirstNameValid,
    lastName,
    setLastName,
    isLastNameValid,
    setLastNameValid,
    businessName,
    setBusinessName,
    isBusinessNameValid,
    setBusinessNameValid,
    address,
    setAddress,
    isAddressValid,
    setAddressValid,
    address2,
    setAddress2,
    isAddress2Valid,
    setAddress2Valid,
    zipCode,
    setZipCode,
    isZipCodeValid,
    setZipCodeValid,
    city,
    setCity,
    isCityValid,
    setCityValid,
    country,
    setCountry,
    isCountryValid,
    setCountryValid,
    phone,
    setPhone,
    isPhoneValid,
    setPhoneValid,
    orgNumber,
    setOrgNumber,
    isOrgNumberValid,
    setOrgNumberValid,
    state,
    setState,
    isStateValid,
    setStateValid,
    validForm,
    setValidForm,
    altEmail,
    isAltEmailValid,
    setAltEmail,
    setAltEmailValid,
  };

  return <TenantContext.Provider value={value}>{children}</TenantContext.Provider>;
};

//for abstraction and changing only the logic and hook will be the same
export const useValid = () => {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
};
