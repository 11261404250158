import styled from '@emotion/styled';

export const UtilityCardContainer = styled.div<{ align?: 'left' | 'center' | 'right' | undefined }>`
  display: flex;
  flex-direction: column;
  [class*='UtilityCardstyle__UtilityCardInnerGridCompositionRoot-sc'] {
    justify-content: ${props => props.align || 'center'};
    [data-component-name*='LinkWithIcon'] {
      margin-top: 0;
    }
    div {
      justify-content: ${props => props.align || 'center'};
      height: fit-content;
    }
  }
`;
