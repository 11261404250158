import { SimpleGrid } from '@uk-source-web/simple-grid';
import React, { useEffect, useRef, useState } from 'react';
import {
  ContainerWrapper,
  DiscountContainer,
  DiscountHelperTextWrapper,
  DiscountWrapper,
  ParagraphWrapper,
} from './BasketDiscount.styles';
import { Paragraph } from '@uk-source-web/paragraph';
import { TextInputWithLabel, TextInputWithLabelProps } from '@uk-source-web/text-input-with-label';
import { Button } from '@uk-source-web/button';
import { LinkWithIcon } from '@uk-source-web/link-with-icon';
import { Spacer } from '@/components/Global/RegisterAccount/RegisterAccount.styles';
import { Link } from '@uk-source-web/link';
import BasketMoreInformationContent from './BasketMoreInformationContent/BasketMoreInformationContent';
import {
  AppDispatch,
  AppState,
  UserBasketItem,
  applyDiscount,
  clearDiscount,
  useAppDispatch,
  useAppSelector,
} from '@/store';
import { ProductBasketDiscount } from '@/services/discount.services';
import { getCurrencySymbol } from '@/helpers/findPrices';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';

const BasketDiscount = ({ shouldShowDiscountInput = true }: { shouldShowDiscountInput: boolean }) => {
  type TypedDispatch = ThunkDispatch<AppState, unknown, any>;
  const dispatch: TypedDispatch = useDispatch();
  const [discountCode, setDiscountCode] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [state, setState] = useState<TextInputWithLabelProps['state']>(undefined);

  const { discountData, loading, error } = useAppSelector(state => state.userDiscount);

  const { user } = useAppSelector(({ userSession }) => userSession);
  const { userBasket } = useAppSelector(({ userBasket: { data } }) => ({
    userBasket: data,
  }));

  const prevBasketRef = useRef<UserBasketItem[]>([]);
  const totalItemsWithDiscount = discountData?.itemDiscount
    .filter(item => item.productRecurringDiscount > 0 || item.productUpfrontDiscount > 0)
    .reduce((sum, item) => sum + item.numberOfItems, 0);

  const totalItemDiscountAmount = (
    discountData?.subtotal && discountData?.dueNow ? discountData?.subtotal - discountData?.dueNow : 0
  ).toFixed(2);

  const discountedProducts =
    discountData?.itemDiscount?.filter(
      ({ productUpfrontDiscount, productRecurringDiscount }) =>
        productUpfrontDiscount !== 0 || productRecurringDiscount !== 0
    ) || [];

  const discountedProductIds = discountedProducts.map(({ productId }) => productId);

  // handle basket change
  useEffect(() => {
    const prevBasket = prevBasketRef.current || [];
    const getBasketData = (items: UserBasketItem[]) =>
      (items || []).map(item => ({
        productId: item.productId,
        quantity: item.quantity,
      }));

    const prevBasketData = getBasketData(prevBasket);
    const currentBasketData = getBasketData(userBasket?.items);

    let productIdChange: string | null = null;

    const hasQuantityChanged = currentBasketData.some(currentItem => {
      productIdChange = currentItem.productId;

      const prevItem = prevBasketData.find(p => p.productId === currentItem.productId);
      return prevItem ? prevItem.quantity !== currentItem.quantity : true;
    });

    if (hasQuantityChanged && discountedProductIds.includes(productIdChange || '')) {
      applyDiscountToBasket(discountData?.discountName);
    }

    prevBasketRef.current = userBasket?.items || [];
  }, [userBasket.items]);

  const applyDiscountToBasket = async (discountCode: any) => {
    const products = userBasket.items.map(item => ({
      id: item.productId,
      price: item?.dxlInformation?.price[0]?.price || 0,
      numberOfUnits: item?.quantity ?? 1,
    })) as ProductBasketDiscount[];
    try {
      let resp = await dispatch(
        applyDiscount({
          productsDiscount: { discountCode, products },
          csrfToken: user?.csrfToken || '',
        })
      );
      if (resp.meta.requestStatus === 'fulfilled') {
        setState(undefined);
      }
    } catch (error: any) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
      setState('error');
    }
  }, [error]);

  const handleValidateInput = (value: string) => {
    if (value.trim() === '') {
      return 'Discount code cannot be empty.';
    } else if (value.length < 5) {
      return 'Discount code must have at least 5 characters.';
    }
    return '';
  };

  const handleDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedDiscountValue = e.target.value.trim();
    setDiscountCode(trimmedDiscountValue);

    const validationError = handleValidateInput(trimmedDiscountValue);

    if (validationError) {
      setErrorMessage(validationError);
      setState('error');
    } else {
      setErrorMessage('');

      // If the previous error was from the API, reset it
      if (error?.statusCode === 400 || error?.statusCode === 401) {
        setErrorMessage('');
      }

      setState('success');
    }
  };
  const handleDiscountCode = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    await applyDiscountToBasket(discountCode);
    setDiscountCode('');
  };

  const handleIconClick = () => {
    dispatch(clearDiscount());
    setDiscountCode('');
  };

  const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleValidateInput(e.target.value);
  };

  return (
    <SimpleGrid
      columns={{
        sm: 1,
        md: 1,
      }}
      verticalAlign="stretch"
      justify="center"
    >
      <ContainerWrapper>
        {shouldShowDiscountInput && (
          <>
            <DiscountWrapper errorMessage={errorMessage.length}>
              <TextInputWithLabel
                id="defaultTextInput"
                state={state}
                fieldWrapper={{
                  label: 'Apply a discount code',
                  width: 'full',
                  stateText: errorMessage,
                }}
                textInput={{
                  id: 'discnout',
                  type: 'text',
                  placeholder: 'Enter Code',
                  value: discountCode,
                  onChange: handleDiscountChange,
                  onBlur: handleInputBlur,
                }}
              />
              <Button
                text="Apply"
                appearance="alt1"
                width="auto"
                onClick={handleDiscountCode}
                loading={loading}
                state={discountCode === '' || state === 'error' || state === 'warn' ? 'disabled' : undefined}
              />
            </DiscountWrapper>
            {discountData && !loading && (
              <DiscountHelperTextWrapper>
                <div>
                  <Paragraph size={1} weight={2}>
                    {discountData?.discountName}
                  </Paragraph>
                  <Paragraph size={1} weight={2}>
                    Applied to {totalItemsWithDiscount} item
                    {totalItemsWithDiscount !== 1 ? 's' : ''} - {getCurrencySymbol()}
                    {totalItemDiscountAmount}
                  </Paragraph>
                </div>
                <LinkWithIcon
                  appearance="secondary"
                  text=""
                  icon={{
                    name: 'close',
                    group: 'system',
                  }}
                  onClick={handleIconClick}
                  srText="Screen reader text!"
                />
              </DiscountHelperTextWrapper>
            )}
          </>
        )}
      </ContainerWrapper>
      <DiscountContainer>
        <BasketMoreInformationContent />
        <Spacer h={30} />
        {shouldShowDiscountInput && (
          <>
            <ParagraphWrapper>
              <Paragraph size={1} weight={1}>
                {' '}
                By proceeding and clicking on “Continue” you are accepting the&nbsp;
              </Paragraph>
              <Link
                htmlAttributes={{ target: '_blank' }}
                href="https://n.vodafone.ie/content/dam/gowingmyself/pdfs/vbu-pdfs-2023/Vodafone%20Business%20Marketplace%20Terms-%20as%20of%20February%202023.pdf"
                text="Terms and Conditions"
                showUnderline={true}
                size={1}
              />
              <Paragraph size={1} weight={1}>
                &nbsp;and&nbsp;
              </Paragraph>
              <Link
                htmlAttributes={{ target: '_blank' }}
                href="https://n.vodafone.ie/privacy.html"
                text="Privacy Supplement current"
                showUnderline={true}
                size={1}
              />
            </ParagraphWrapper>
            <Spacer h={20} />
          </>
        )}
      </DiscountContainer>
    </SimpleGrid>
  );
};

export default BasketDiscount;
