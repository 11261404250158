import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { DiscountCodeProps, applyDiscountCode } from '@/services/discount.services';
import { DiscountDataProps } from '@/components/pageBuilder/Sections/SWBasket/BasketDiscount/BasketDiscount.types';

export interface DiscountState {
  discountData: DiscountDataProps | null;
  loading: boolean;
  error: { message: string; statusCode: number } | null;
}

const initialState: DiscountState = {
  discountData: null,
  loading: false,
  error: null,
};

// ✅ Async thunk for applying discount
export const applyDiscount = createAsyncThunk<
  DiscountDataProps, // Success return type
  { productsDiscount: DiscountCodeProps; csrfToken: string }, // Args
  { rejectValue: string } // Error return type
>('discount/applyDiscount', async ({ productsDiscount, csrfToken }, { rejectWithValue }) => {
  try {
    const result = await applyDiscountCode(
      productsDiscount,
      csrfToken,
      () => {},
      () => {}
    );
    if (result.success) {
      return result.data; // Return the discount data
    }

    // Handle specific error cases
    if (result.statusCode === 400) {
      return rejectWithValue('Invalid discount code. Please check and try again.');
    } else if (result.statusCode === 401) {
      return rejectWithValue('Session expired. Cookie unavailable.');
    } else {
      return rejectWithValue('An unexpected error occurred. Please try again later.');
    }
  } catch (error) {
    return rejectWithValue('An error occurred while applying the discount.');
  }
});

// ✅ Create the slice
export const userDiscountSlice = createSlice({
  name: 'userDiscount',
  initialState,
  reducers: {
    clearDiscount: state => {
      state.discountData = null;
      state.error = null;
    },
    setError: (state, action: PayloadAction<{ message: string; statusCode: number } | null>) => {
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(applyDiscount.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(applyDiscount.fulfilled, (state, action) => {
        state.loading = false;
        state.discountData = action.payload;
      })
      .addCase(applyDiscount.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload === 'string') {
          state.error = { message: action.payload, statusCode: 500 }; // Default status code if missing
        } else {
          state.error = action.payload as unknown as { message: string; statusCode: number };
        }
      });
  },
});

export const { clearDiscount, setError } = userDiscountSlice.actions;
export const userDiscountReducer = userDiscountSlice.reducer;

const persistConfig = {
  key: 'vf_discount',
  storage: storageSession,
};

export const persistedDiscountReducer = persistReducer<DiscountState>(persistConfig, userDiscountReducer);
