import styled from '@emotion/styled';
import { SelectInput } from '@legacy-vfuk/core-select-input';
import { rem } from 'polished';

export const SWSmallPricingCardWrapper = styled.div`
  width: ${rem('295px')};
  height: ${rem('416px')};
  min-height: ${rem('416px')};
  min-width: ${rem('295px')};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${rem('20px')};
  border-radius: ${rem('10px')};
  border: ${rem('1px')} solid #bebebe;
  background-color: var(--white);
`;

export const SWSmallPricingCardTitle = styled.h3`
  font-size: ${rem('28px')};
  font-weight: 700;
  text-align: center;
  margin-bottom: ${rem('16px')};
  line-height: ${rem('32px')};
`;

export const SWSmallPricingCardDropdown = styled(SelectInput)`
  font-size: ${rem('14px')};
  font-weight: 400;
`;

export const SWSmallPricingCardDetails = styled.div`
  margin-top: ${rem('20px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const SWSmallPricingCardDetailsPrice = styled.div`
  font-size: ${rem('40px')};
  font-weight: 700;
`;

export const SWSmallPricingCardDetailsPeriod = styled.div`
  font-size: ${rem('14px')};
  font-weight: 400;
`;

export const Spacer = styled.div<{ height: string }>`
  height: ${({ height }) => height};
`;
