import styled from '@emotion/styled';
import { rem } from 'polished';

export const Wrapper = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  background-color: ${props => props.backgroundColor || 'var(--grey)'};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .slick-slider {
    user-select: auto;
  }

  .slick-slide > div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;

    > div {
      display: flex !important;
      justify-content: center;
      align-items: center;
      max-width: ${rem('345px')};

      @media (min-width: ${props => props.theme.breakpoints.md}px) {
        max-width: ${rem('382px')};
      }
    }
  }

  [class*='CarouselArrowstyle__CarouselArrow-sc'] {
    z-index: 4;
    background: var(--vodafone-colors-white);

    [class*='Iconstyle__Icon-sc'] {
      svg {
        width: ${rem('30px')};
        height: ${rem('30px')};

        :focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  [class*='FunctionalCarouselstyle__CarouselDotPagerList-sc'] {
    text-align: center;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
      margin-bottom: ${rem('64px')};
    }
  }
  [class*='FunctionalCarouselstyle__Carousel-sc'] {
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      margin: 0;
    }
  }
  [class*='Interactionstyle__Button-sc'] {
    border-radius: 0;
  }
`;

export const CarouselWrapper = styled.div`
  width: 80%;
  max-width: ${rem('1180px')};
  margin: 0 auto;
  display: flex;
`;

export const CarouselTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${rem('28px')};
  margin-top: ${rem('60px')};
`;
