import IconButton from '@legacy-vfuk/core-icon-button';
import TextInput from '@legacy-vfuk/core-text-input';

import { QuantityInputProps } from './QuantityInput.props';
import { IconButtonWrapper, InputWrapper } from './QuantityInput.styles';

const QuantityInput = ({
  quantity,
  handleIncreaseQty,
  handleDecreaseQty,
  handleChangeQuantity,
  size,
}: QuantityInputProps) => {
  return (
    <InputWrapper size={size || 1}>
      <IconButtonWrapper ml={1} size={size || 1}>
        <IconButton
          {...(quantity === 1 && { state: 'disabled' })}
          srText="Decrease quantity"
          size={1}
          icon={{ name: 'minus' }}
          onClick={handleDecreaseQty}
          dataAttributes={{
            value: String(quantity),
          }}
          appearance="alt1"
        />
      </IconButtonWrapper>

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore: Necessary to use TextInput instead the suggested component */}
      <TextInput id="action" type="number" placeholder="0" value={String(quantity)} onChange={handleChangeQuantity} />
      <IconButtonWrapper mr={1} right={0} size={size || 1}>
        <IconButton
          appearance="alt1"
          srText="Increase quantity"
          size={1}
          icon={{ name: 'plus' }}
          onClick={handleIncreaseQty}
          dataAttributes={{
            value: String(quantity),
          }}
        />
      </IconButtonWrapper>
    </InputWrapper>
  );
};

export default QuantityInput;
