import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';
export const SectionGroupedUtilityCardContainer = styled.div<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${rem('20px')};
  background-color: ${props => props.backgroundColor || 'var(--grey)'};
  padding-top: ${rem('60px')};
  padding-bottom: ${rem('100px')};
`;

export const UtilityCardContainer = styled.div<{ numberOfCards: number }>`
  display: grid;
  width: ${rem('820px')};
  height: ${rem('324px')};
  margin: 0 auto;
  gap: ${rem('12px')};
  ${props => {
    if (props.numberOfCards === 1) {
      return css`
        grid-template-columns: 1fr;
      `;
    }
    if (props.numberOfCards === 2) {
      return css`
        grid-template-columns: 1fr 1fr;
      `;
    }
    if (props.numberOfCards === 3) {
      return css`
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        & > :nth-of-type(2) {
          grid-row: span 2;
        }
      `;
    }
    if (props.numberOfCards === 4) {
      return css`
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      `;
    }
  }}
  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    display: grid;
    width: 95%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

export const Heading = styled.h2`
  font-size: ${rem('28px')};
  font-weight: 400;
  line-height: ${rem('32px')};
  color: var(--black);
  text-align: center;
`;
