import { InteractionEvent } from '@legacy-vfuk/core-interaction/dist/Interaction.types';
import TopNavigation from '@legacy-vfuk/core-top-navigation';
import { Link } from '@legacy-vfuk/core-top-navigation/dist/TopNavigation.types';
import { withPrefix } from 'gatsby';
import { useState, useEffect } from 'react';

import { useModalContext } from '@/hooks/modal/useModal';
import { useSWTopNavigation } from '@/hooks/useSWTopNavigation';
import { clearDiscount, clearUserSession, useAppDispatch, useAppSelector } from '@/store';

import { TopNavigationWrapper } from './SWTopNavigation.styles';

const SWTopNavigation = () => {
  const { id, topNav, primaryNav, logoNav, secondaryNav } = useSWTopNavigation();
  const dispatch = useAppDispatch();
  const userBasket = useAppSelector(state => state.userBasket.data);
  const { user } = useAppSelector(state => state.userSession);
  const { toggleModal } = useModalContext();
  const [showButton, setShowButton] = useState<boolean>(false);

  useEffect(() => {
    if (user) renderUserName();
  }, []);

  useEffect(() => {
    if (user && showButton) renderSignOutButton();
    if (user && !showButton) deleteSignOutButton();
  }, [showButton]);

  const handleSignOut = (event: InteractionEvent) => {
    event.preventDefault();
    dispatch(clearUserSession());
    setShowButton(false);
    dispatch(clearDiscount());
  };

  const launchLogin = () => {
    toggleModal(true, 'Login');
  };

  const showSignOut = () => {
    setShowButton(true);
    setTimeout(() => setShowButton(false), 5000);
  };

  const renderUserName = () => {
    const node = document.createElement('p');
    const textnode = document.createTextNode(user?.selectedAccount?.customerAccountName || '');
    node.appendChild(textnode);
    document.querySelectorAll('[aria-label="Primary Navigation"]')[0]?.lastChild?.lastChild?.appendChild(node);
  };

  const renderSignOutButton = () => {
    const node = document.createElement('button');
    node.setAttribute('data-selector', 'Sign Out Button');
    node.setAttribute('class', ' button--sign-out');
    node.innerHTML = 'Sign Out';
    node.onclick = e => {
      handleSignOut(e);
    };
    document.querySelectorAll('[aria-label="Primary Navigation"]')[0]?.lastChild?.lastChild?.appendChild(node);
  };

  const deleteSignOutButton = () => {
    const primaryNav = document.querySelectorAll('[aria-label="Primary Navigation"]')[0];
    const lastChild = primaryNav?.lastChild?.lastChild as Element;
    const button = lastChild?.querySelector('[data-selector="Sign Out Button"]');
    if (button && !showButton) button?.remove();
  };

  const mapPrimaryLinks = (links: Link[]) => {
    return links.map(({ url, ...link }) => {
      const newLink = { ...link } as Link;

      if (link.links) {
        newLink.links = mapPrimaryLinks(link.links);
      } else if (url) {
        newLink.url = withPrefix(url);
      }

      return newLink;
    });
  };

  const mapSecondaryLinks = (links: Link[]) => {
    return links.map(({ url, ...link }) => {
      const newLink = { ...link } as Link;

      switch (link.text) {
        case 'Personal':
          newLink.url = 'https://n.vodafone.ie/en.html';
          break;
        case 'Business':
          newLink.url = 'https://n.vodafone.ie/business.html';
          break;
        default:
          break;
      }

      return newLink;
    });
  };

  return (
    <TopNavigationWrapper user={user?.selectedAccount?.customerAccountName}>
      {primaryNav && (
        <TopNavigation
          {...(topNav && {
            topNav: {
              ...topNav,
              leftLinks: mapSecondaryLinks(topNav.leftLinks || []),
            },
          })}
          primaryNav={{
            ...primaryNav,
            links: primaryNav.links ? mapPrimaryLinks(primaryNav.links) : [],
          }}
          logo={{
            ...logoNav,
            url: 'https://n.vodafone.ie/business.html',
          }}
          {...(secondaryNav && {
            secondaryNav: {
              ...secondaryNav,
              iconNavLinks: user
                ? [
                    {
                      id: 'your-basket',
                      text: 'Your Basket',
                      url: withPrefix('/marketplace/product-list/basket'),
                      icon: 'shopping-trolley',
                      numberBadge: userBasket.items.length || 0,
                    },
                    {
                      id: 'signed-in',
                      text: 'Signed In',
                      url: '',
                      icon: 'signed-in',
                      ...{ onClick: () => showSignOut() },
                    },
                  ]
                : [
                    {
                      id: 'sign-out',
                      text: 'Sign Out',
                      url: '',
                      icon: 'admin',
                      ...{ onClick: () => launchLogin() },
                    },
                  ],
            },
          })}
          mobileNavTrigger={{
            id: `${id}-mobile-nav-trigger`,
            text: '',
          }}
          sideNavigationCloseButton={{
            id: `${id}-close-button`,
            srText: 'Close side navigation',
          }}
          skipLink={{
            text: `${id}-Skip to main content`,
            skipLinkId: '#main',
          }}
        />
      )}
    </TopNavigationWrapper>
  );
};

export default SWTopNavigation;
