import styled from '@emotion/styled';
import { rem } from 'polished';
export const SectionCardDisplayWrapper = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${rem('60px')} 0;
  background-color: ${props => props.backgroundColor};
`;

export const SectionCardDisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem('25px')};
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${rem('20px')};
  flex-wrap: wrap;
  @media (max-width: ${props => props.theme.breakpoints.md}px) {
    flex-direction: column;
  }
`;

export const SCDTitle = styled.div`
  font-size: ${rem('28px')};
  font-weight: 400;
  text-align: center;
  line-height: ${rem('32px')};
`;

export const SDCDescription = styled.div`
  font-size: ${rem('18px')};
  font-weight: 400;
  line-height: ${rem('26px')};
  text-align: center;
`;

export const Divider = styled.div<{ height: string }>`
  width: 100%;
  height: ${props => props.height};
`;
