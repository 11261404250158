import Heading from '@legacy-vfuk/core-heading';
import { NavigationalTabs, Tab } from '@legacy-vfuk/core-navigational-tabs';
import { Element, scroller } from 'react-scroll';

import {
  CONTENTFUL_SECTION_SW_ACCORDION_CARD_CAROUSEL,
  CONTENTFUL_SECTION_SW_CONTENT_BLOCK_WITH_VIDEO_GROUP,
  CONTENTFUL_SECTION_SW_FAQS,
  CONTENTFUL_SECTION_SW_OVERVIEW_FEATURE,
  CONTENTFUL_SECTION_SW_RELATED_ARTICLES,
  CONTENTFUL_SECTION_SW_RELATED_PRODUCTS,
  CONTENTFUL_SECTION_CARD_DISPLAY,
  CONTENTFUL_SECTION_SW_GENERIC_CAROUSEL,
  CONTENTFUL_SECTION_GROUPED_UTILITY_CARD,
} from '@/templates/page-builder-helpers';

import SWAccordionCardCarousel from '../SWAccordionCardCarousel';
import SWContentBlockWithVideo from '../SWContentBlockWithVideo';
import SWFeatureOverview from '../SWFeatureOverview/SWFeatureOverview';
import SWPricingContent, { CONTENTFUL_SECTION_SW_PRICING_CONTENT } from '../SWPricingContent';
import SWRelatedArticles from '../SWRelatedArticles';
import SWRelatedProducts from '../SWRelatedProducts';
import SWSectionFAQs from '../SWSectionFAQs';
import { CustomElement, NavigationalTabsWrapper } from './SWNavigationalTabs.styles';
import { RenderTabProps, SWNavigationalTabsProps, TabProps } from './SWNavigationalTabs.types';
import SWGenericCarousel from '../SWGenericCarousel/SWGenericCarousel';
import SectionCardDisplay from '../SectionCardDisplay/SectionCardDisplay';
import SectionGroupedUtilityCard from '../SectionGroupedUtilityCard/SectionGroupedUtilityCard';

const SWNavigationalTabs = ({ tabs, sticky }: SWNavigationalTabsProps) => {
  const handleOnTabClick = (tabId: string) => {
    scroller.scrollTo(tabId, {
      smooth: true,
      spy: false,
      offset: -113,
    });
  };

  const renderTabs = (tabs: TabProps[]) => {
    return tabs.map(({ ...tab }) => {
      switch (tab.__typename) {
        case CONTENTFUL_SECTION_SW_ACCORDION_CARD_CAROUSEL: {
          return (
            <Tab key={tab.id} id={tab.id} text={tab.tabName}>
              <RenderTab tab={tab}>
                <SWAccordionCardCarousel key={tab.id} {...tab} />
              </RenderTab>
            </Tab>
          );
        }

        case CONTENTFUL_SECTION_SW_CONTENT_BLOCK_WITH_VIDEO_GROUP: {
          return (
            <Tab key={tab.id} id={tab.id} text={tab.tabName}>
              <RenderTab tab={tab}>
                <SWContentBlockWithVideo key={tab.id} {...tab} />
              </RenderTab>
            </Tab>
          );
        }

        case CONTENTFUL_SECTION_SW_PRICING_CONTENT: {
          return (
            <Tab key={tab.id} id={tab.id} text={tab.tabName}>
              <RenderTab tab={tab}>
                <SWPricingContent key={tab.id} {...tab} />
              </RenderTab>
            </Tab>
          );
        }

        case CONTENTFUL_SECTION_SW_OVERVIEW_FEATURE: {
          return (
            <Tab key={tab.id} id={tab.id} text={tab.tabName}>
              <RenderTab tab={{ id: tab.id }}>
                <SWFeatureOverview key={tab.id} {...tab} />
              </RenderTab>
            </Tab>
          );
        }

        case CONTENTFUL_SECTION_SW_RELATED_ARTICLES: {
          return (
            <Tab key={tab.id} id={tab.id} text={tab.tabName}>
              <RenderTab tab={tab}>
                <SWRelatedArticles key={tab.id} {...tab} />
              </RenderTab>
            </Tab>
          );
        }

        case CONTENTFUL_SECTION_SW_RELATED_PRODUCTS: {
          return (
            <Tab key={tab.id} id={tab.id} text={tab.tabName}>
              <RenderTab tab={tab}>
                <SWRelatedProducts key={tab.id} {...tab} />
              </RenderTab>
            </Tab>
          );
        }

        case CONTENTFUL_SECTION_SW_FAQS: {
          return (
            <Tab key={tab.id} id={tab.id} text={tab.heading}>
              <RenderTab tab={tab}>
                <SWSectionFAQs key={tab.id} {...tab} />
              </RenderTab>
            </Tab>
          );
        }

        case CONTENTFUL_SECTION_SW_GENERIC_CAROUSEL: {
          return (
            <Tab key={tab.id} id={tab.id} text={tab.title}>
              <RenderTab tab={tab} showHeading={false}>
                <SWGenericCarousel key={tab.id} {...tab} />
              </RenderTab>
            </Tab>
          );
        }

        case CONTENTFUL_SECTION_CARD_DISPLAY: {
          return (
            <Tab key={tab.id} id={tab.id} text={tab.title}>
              <RenderTab tab={tab} showHeading={false}>
                <SectionCardDisplay key={tab.id} {...tab} />
              </RenderTab>
            </Tab>
          );
        }

        case CONTENTFUL_SECTION_GROUPED_UTILITY_CARD: {
          return (
            <Tab key={tab.id} id={tab.id} text={tab.tabName}>
              <RenderTab tab={tab} showHeading={false}>
                <SectionGroupedUtilityCard key={tab.id} {...tab} />
              </RenderTab>
            </Tab>
          );
        }

        default: {
          return <></>;
        }
      }
    });
  };
  return (
    <NavigationalTabsWrapper sticky={sticky} tabsNumber={tabs.length}>
      <NavigationalTabs accessibilityInfiniteScroll={true} onTabClick={handleOnTabClick}>
        {renderTabs(tabs)}
      </NavigationalTabs>
    </NavigationalTabsWrapper>
  );
};

const RenderTab = ({ tab, children, showHeading = true }: RenderTabProps) => {
  const { backgroundColor } = tab;
  return (
    <Element name={tab.id}>
      {showHeading ? (
        <CustomElement backgroundColor={backgroundColor || 'primary2.default'}>
          {tab.__typename === 'ContentfulSectionSwfaQs' ? null : tab.tabName ? (
            <Heading level={2} justify={'center'} text={tab.tabName} />
          ) : tab.name ? (
            <Heading level={2} justify={'center'} text={tab.name} />
          ) : null}
          {children}
        </CustomElement>
      ) : (
        <>{children}</>
      )}
    </Element>
  );
};

export default SWNavigationalTabs;
