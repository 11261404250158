import { DxlInformation, ProductBundle } from '@/types/Product.types';
import {
  SWSmallPricingCardTitle,
  SWSmallPricingCardWrapper,
  SWSmallPricingCardDetails,
  SWSmallPricingCardDropdown,
  SWSmallPricingCardDetailsPrice,
  SWSmallPricingCardDetailsPeriod,
  Spacer,
} from './SWSmallPricingCard.styles';
import { Button } from '@legacy-vfuk/core-button';
import { useBasket } from '@/hooks';
import QuantityInput from '@/components/maac-ui/QuantityInput/QuantityInput';
import { retrieveProduct } from '@/services/product.services';
import axios from 'axios';
import { TError } from '@/services/error.types';
import { useEffect, useMemo, useState } from 'react';
import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { SWSmallPricingCardProps } from './SWSmallPricingCard.types';

export const SWSmallPricingCard = ({
  product,
  customName,
  buttonAction,
  priceDescription,
}: SWSmallPricingCardProps) => {
  const [selectedPrice, setSelectedPrice] = useState<{ name: string; price: number } | null>(null);

  const [productBundle, setProductBundle] = useState<ProductBundle & { dxlInformation?: DxlInformation }>();

  const { quantity, buttonText, handleChangeQuantity, handleDecreaseQty, handleIncreaseQty, handleButtonAction } =
    useBasket({
      item: { ...product, buttonsAction: buttonAction, selectedPriceName: selectedPrice?.name },
      nextStep: '',
      pathname: '',
    });

  const options = useMemo(() => {
    if (productBundle?.dxlInformation?.price?.length && productBundle?.dxlInformation?.price?.length > 0) {
      return productBundle.dxlInformation.price.map(price => {
        if (price.characteristics?.maxUnit && price.characteristics?.minUnits) {
          return {
            text: `${price.characteristics?.minUnits.split('.')[0]} - ${
              price.characteristics?.maxUnit.split('.')[0]
            } users`,
            value: JSON.stringify({ name: price.name, price: price.price }),
          };
        }
        return {
          text: 'Unknown user range',
          value: JSON.stringify({ name: price.name, price: price.price }),
        };
      });
    }
    return [];
  }, [productBundle]);

  const fetchProductBundle = async () => {
    try {
      product.dxlInformation = undefined;
      const retrievedProdutc = await retrieveProduct(product.productId);
      if (
        !axios.isAxiosError(retrievedProdutc) &&
        retrievedProdutc.status === 200 &&
        retrievedProdutc.data &&
        retrievedProdutc.data.id
      ) {
        product.dxlInformation = retrievedProdutc.data.dxlInformation;
        setProductBundle(product);
      } else {
      }
    } catch (err) {
      const error = err as TError;
      isEnvDevelopment() && console.log(error?.response?.data.error);
    }
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      const price = JSON.parse(event.target.value) as { name: string; price: number };
      setSelectedPrice(price);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchProductBundle();
  }, []);

  return (
    <SWSmallPricingCardWrapper>
      <SWSmallPricingCardTitle>{customName || product.name}</SWSmallPricingCardTitle>
      <SWSmallPricingCardDropdown options={options} onChange={handlePriceChange}></SWSmallPricingCardDropdown>
      <SWSmallPricingCardDetails>
        <SWSmallPricingCardDetailsPrice>
          &euro; {(Number(selectedPrice?.price || productBundle?.dxlInformation?.price[0].price) * quantity).toFixed(2)}
        </SWSmallPricingCardDetailsPrice>
        <SWSmallPricingCardDetailsPeriod>{priceDescription}</SWSmallPricingCardDetailsPeriod>
      </SWSmallPricingCardDetails>
      <Spacer height="30px" />
      <QuantityInput
        size={2}
        quantity={quantity}
        handleIncreaseQty={handleIncreaseQty}
        handleDecreaseQty={handleDecreaseQty}
        handleChangeQuantity={handleChangeQuantity}
      />
      <Spacer height="30px" />
      <Button
        text={buttonText(selectedPrice?.name)}
        onClick={e => handleButtonAction(e, { customPriceName: selectedPrice?.name })}
      />
    </SWSmallPricingCardWrapper>
  );
};
