import { Product } from '@/types/Product.types';
import BasketItemBundleStyles, { BundleItem, BundleItemThumb } from './BasketItemBundle.styles';
import { getImage, getSrc } from 'gatsby-plugin-image';
import Image from '@legacy-vfuk/core-image';
import { Paragraph } from '@uk-source-web/paragraph';

interface BasketItemBundle {
  bundle: Product[];
}

const BasketItemBundle = ({ bundle }: BasketItemBundle) => {
  const thumbnailIconSrc = (item: any) =>
    typeof item?.thumbnailIcon == 'string'
      ? item?.thumbnailIcon
      : getSrc(item?.thumbnailIcon) ?? getImage(item?.thumbnailIcon)?.placeholder?.fallback;
  return (
    <BasketItemBundleStyles>
      {bundle.map(item => (
        <BundleItem>
          <BundleItemThumb>
            <Image alt="image" sm={{ src: thumbnailIconSrc(item) ?? '' }} />
          </BundleItemThumb>
          <Paragraph>{item?.name}</Paragraph>
        </BundleItem>
      ))}
    </BasketItemBundleStyles>
  );
};

export default BasketItemBundle;
