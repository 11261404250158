import { SectionGroupedUtilityCardContainer, Heading, UtilityCardContainer } from './SectionGroupedUtilityCard.styles';
import { SectionGroupedUtilityCardProps } from './SectionGroupedUtilityCard.types';
import SWUtilityCard from '@/components/pageBuilder/Elements/SWUtilityCard/SWUtilityCard';
import { useWindowSize } from 'usehooks-ts';

const SectionGroupedUtilityCard = ({ utilityCards, backgroundColor, heading }: SectionGroupedUtilityCardProps) => {
  const { width } = useWindowSize();
  const isMobile = width <= 1024;
  return (
    <SectionGroupedUtilityCardContainer backgroundColor={backgroundColor}>
      {heading && <Heading>{heading}</Heading>}
      <UtilityCardContainer numberOfCards={utilityCards.length}>
        {utilityCards.map((utilityCard, index) => (
          <SWUtilityCard
            key={utilityCard.id + index}
            {...utilityCard}
            orientation={isMobile ? 'horizontal' : index % 2 === 0 ? 'horizontal' : 'vertical'}
          />
        ))}
      </UtilityCardContainer>
    </SectionGroupedUtilityCardContainer>
  );
};

export default SectionGroupedUtilityCard;
