import CardCarousel from '@legacy-vfuk/core-card-carousel';
import { FC } from 'react';

import { CarouselWrapper, Wrapper, CarouselTitle } from './SWGenericCarousel.styles';
import { SWGenericCarouselProps } from './SWGenericCarousel.types';
import AccordionCard from '../../Elements/AccordionCard/AccordionCard';
import { Flex } from '@chakra-ui/react';
const SWGenericCarousel: FC<SWGenericCarouselProps> = ({
  name,
  carouselItems,
  carouselType,
  title,
  backgroundColor,
}: SWGenericCarouselProps) => {
  const CarouselContents = carouselItems
    ? carouselItems.map((item, index) => {
        return (
          <Flex key={index} direction="column">
            {carouselType === 'accordionCard' ? <AccordionCard {...item} /> : null}
          </Flex>
        );
      })
    : [];

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <CarouselTitle>{title}</CarouselTitle>
      <CarouselWrapper>
        <CardCarousel
          slidesToShow={{ sm: 1, md: 2, lg: 2, xl: 3 }}
          slidesToScroll={{ sm: 1 }}
          srName={`${name} Generic Carousel`}
          elevatedCards
          arrows={{ position: 'outside' }}
        >
          {CarouselContents}
        </CardCarousel>
      </CarouselWrapper>
    </Wrapper>
  );
};

export default SWGenericCarousel;
