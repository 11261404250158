import { useEffect, useMemo, useState } from 'react';
import { CardRenderProps } from './CardRender.types';
import { DetailsAndPricingProps } from '../CardElements/DetailsAndPricing/DetailsAndPricing.types';
import { retrieveProduct } from '@/services/product.services';
import axios from 'axios';

import { OnChangeEvent } from '@legacy-vfuk/core-select-input';
import { SWExpandingCard, SWFlippingCard, SWIncludesCard, SWStandardCard } from '../CardTypes';
import { useModalContext } from '@/hooks/modal/useModal';

const CardRender = ({
  pathname,
  item,
  mergedProduct,
  itemStepName,
  isMiddleOne,
  includesCards,
  toggleStatus,
  setToggleStatus,
  mainItem,
}: CardRenderProps) => {
  const { setErrorInfo, toggleModal } = useModalContext();
  const [selectedEditionId, setSelectedEditionId] = useState<DetailsAndPricingProps['selectedEdition']>();

  const { isFlippingCard, isExpandingCard, bundleIncludes, thumbnailIcon } = item;

  const shouldChangeTheme = isMiddleOne && bundleIncludes ? bundleIncludes?.length > 0 : false;

  const checkedEditionItem = useMemo(() => {
    if (item.dxlInformation?.periods && item.dxlInformation?.periods.length > 0 && selectedEditionId) {
      return item.dxlInformation?.periods.find(period => period.uuid === selectedEditionId);
    } else if (item.dxlInformation?.periods && item.dxlInformation?.periods.length > 0 && !selectedEditionId) {
      setSelectedEditionId(item.dxlInformation?.periods[0].uuid);
      return item.dxlInformation?.periods[0];
    } else {
      return undefined;
    }
  }, [item, selectedEditionId]);

  const handleSelectedEdition = (event: React.ChangeEvent<HTMLInputElement> | OnChangeEvent) => {
    setSelectedEditionId(event.target.value);
  };

  const getDxlInformation = async (): Promise<void> => {
    const newProduct = await retrieveProduct(item.productId);
    if (!axios.isAxiosError(newProduct) && newProduct.status === 200 && newProduct.data && newProduct.data.id) {
      item.dxlInformation = newProduct.data.dxlInformation;
    } else if (axios.isAxiosError(newProduct)) {
      setErrorInfo({
        statusType: newProduct.response?.data?.error,
        statusCode: newProduct.response?.data?.code || newProduct.response?.status,
        requestUrl: 'catalogues',
      });
      toggleModal(true, 'Error');
    }
  };

  useEffect(() => {
    !item.dxlInformation && getDxlInformation();
  }, [item]);

  if (isFlippingCard && !isExpandingCard) {
    return (
      <SWFlippingCard
        {...{
          item,
          mergedProduct,
          itemStepName,
          shouldChangeTheme,
          thumbnailIcon,
          selectedEditionItem: selectedEditionId,
          mainItem,
        }}
      />
    );
  } else if (isExpandingCard && !isFlippingCard) {
    return (
      <SWExpandingCard
        {...{
          item,
          mergedProduct,
          itemStepName,
          shouldChangeTheme,
          selectedEditionItem: selectedEditionId,
          mainItem,
        }}
      />
    );
  } else if (!isFlippingCard && !isExpandingCard && includesCards && includesCards.length > 0) {
    return (
      <SWIncludesCard
        {...{
          item,
          mergedProduct,
          itemStepName,
          shouldChangeTheme,
          includesCards,
          selectedEditionItem: selectedEditionId,
          mainItem,
        }}
      />
    );
  } else if (!isFlippingCard && !isExpandingCard && !includesCards) {
    return (
      <SWStandardCard
        {...{
          pathname,
          item,
          mergedProduct,
          itemStepName,
          shouldChangeTheme,
          toggleStatus,
          setToggleStatus,
          selectedEditionItem: selectedEditionId,
          checkedEditionItem,
          handleSelectedEdition,
          mainItem,
        }}
      />
    );
  } else {
    return '';
  }
};

export default CardRender;
