import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import { isServer } from '@/helpers/isServer';

import { userBasketPersistReducer, userBasketReducer } from './slices/userBasketSlice';
import { userSessionPersistReducer, userSessionReducer } from './slices/userSessionSlice';
import { UserConfigureBundlePersistReducer, UserConfigureBundleReducer } from './slices/userConfigureBundleSlice';
import { persistedDiscountReducer, userDiscountReducer } from './slices/userDiscountSlice';

export const store = isServer
  ? configureStore({
      reducer: {
        userBasket: userBasketReducer,
        userSession: userSessionReducer,
        userConfigureBundle: UserConfigureBundleReducer,
        userDiscount: userDiscountReducer,
      },
    })
  : configureStore({
      reducer: {
        userBasket: userBasketPersistReducer,
        userSession: userSessionPersistReducer,
        userConfigureBundle: UserConfigureBundlePersistReducer,
        userDiscount: persistedDiscountReducer,
      },
      middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          },
        });
      },
    });

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export * from './slices/userBasketSlice';
export * from './slices/userSessionSlice';
export * from './slices/userConfigureBundleSlice';
export * from './slices/userDiscountSlice';

if (!isServer && process.env.GATSBY_OPCO == 'PT') {
  window.addEventListener('beforeunload', async () => {
    await persistor.flush();
  });
}
