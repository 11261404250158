import {
  CollapsibleContainer,
  CollapsibleContainerBody,
  CollapsibleContainerHeader,
} from '@uk-source-web/collapsible-container';
import {
  BottomAccordionCardWrapper,
  CollapsibleHeaderStyles,
  CollapsibleWrapperStyles,
  Divider,
  AccordionCardFeature,
  AccordionTitle,
  AccordionCardImage,
} from './AccordionCard.styles';
import { AccordionCardContainer } from './AccordionCard.styles';
import { Icon } from '@uk-source-web/icon';
import { AccordionCardProps } from './AccordionCard.types';
import { getSrc, getImage } from 'gatsby-plugin-image';

const AccordionCard = ({ title, description, features = [], image }: AccordionCardProps) => {
  const imageSrc = getSrc(image) || '' || getImage(image)?.placeholder?.fallback || '';

  return (
    <AccordionCardContainer>
      <AccordionCardImage src={imageSrc} />
      <CollapsibleWrapperStyles>
        <CollapsibleContainer>
          <CollapsibleContainerHeader>
            <CollapsibleHeaderStyles>
              <AccordionTitle>{title}</AccordionTitle>
              <span>{description}</span>
            </CollapsibleHeaderStyles>
          </CollapsibleContainerHeader>
          <BottomAccordionCardWrapper>
            <CollapsibleContainerBody>
              <Divider />
              {features.map(feature => (
                <AccordionCardFeature>
                  <Icon name={'tick-outline'} group="system" appearance="secondary" /> {feature}
                </AccordionCardFeature>
              ))}
            </CollapsibleContainerBody>
          </BottomAccordionCardWrapper>
        </CollapsibleContainer>
      </CollapsibleWrapperStyles>
    </AccordionCardContainer>
  );
};

export default AccordionCard;
